import React from 'react';
import ConnectButton from './ConnectButton';
import './App.css';

const App = () => {
  return (
    <div className="App">
      <header className="App-header">
        <img src="/logo.png" className="App-logo" alt="logo" />
        <h1>foxy memecoin</h1>
        <h2>foxy are build on best amazing L2 superchain Lisk</h2>
        <ConnectButton />
      </header>
    </div>
  );
};

export default App;
