import React, { useState } from 'react';
import { createWeb3Modal, defaultConfig, useWeb3ModalProvider, useWeb3ModalAccount } from '@web3modal/ethers/react';
import { BrowserProvider, Contract, ethers } from 'ethers';
import './ConnectButton.css';

// 1. Get projectId
const projectId = 'a20e94608f089564da29f00e90194b36';

// 2. Set chains
const mainnet = {
  chainId: 1135,
  name: 'Lisk',
  currency: 'ETH',
  explorerUrl: 'https://blockscout.lisk.com',
  rpcUrl: 'https://rpc.api.lisk.com'
};

// 3. Create a metadata object
const metadata = {
  name: 'foxy memecoin',
  description: 'foxy memecoin build on best amazing L2 superchain Lisk',
  url: 'https://foxyonlisk.xyz', // origin must match your domain & subdomain
  icons: ['https://foxyonlisk.xyz/icons/']
};

// 4. Create Ethers config
const ethersConfig = defaultConfig({
  /*Required*/
  metadata,

  /*Optional*/
  enableEIP6963: true, // true by default
  enableInjected: true, // true by default
  enableCoinbase: true, // true by default
  rpcUrl: 'https://rpc.api.lisk.com', // used for the Coinbase SDK
  defaultChainId: 1135, // used for the Coinbase SDK
});

// 5. Create a Web3Modal instance
createWeb3Modal({
  ethersConfig,
  chains: [mainnet],
  chainImages: {
    1135: 'https://media.cms.gelato.network/bridge_favicon_Lisk_c97e710a13/bridge_favicon_Lisk_c97e710a13.svg'
  },
  projectId,
  enableAnalytics: true // Optional - defaults to your Cloud configuration
});

export default function ConnectButton() {
  const { address, chainId, isConnected } = useWeb3ModalAccount();
  const { walletProvider } = useWeb3ModalProvider();
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  async function claimTokens() {
    if (!isConnected) {
      setError('User disconnected');
      setSuccess(null);
      return;
    }

    const ethersProvider = new BrowserProvider(walletProvider);
    const signer = await ethersProvider.getSigner();
    const contractAddress = '0xdaF330d4D4A712B19caE4F9d3FF2123Ec722Df9E';
    const abi = [
      'function claim() external payable'
    ];

    const contract = new Contract(contractAddress, abi, signer);
    const value = ethers.parseEther('0.0002');

    try {
      const tx = await contract.claim({ value });
      await tx.wait();
      console.log('Claim successful');
      setError(null); // Reset error on success
      setSuccess('Congratulations! You have successfully claimed 100,000,000 foxy!');
    } catch (error) {
      console.error('Claim failed', error);
      setError('You have already claimed your tokens.');
      setSuccess(null);
    }
  }

  return (
    <>
      <w3m-button />
      {isConnected && (
        <>
          <button onClick={claimTokens} className="claim-button">Claim Tokens</button>
          {error && <p className="error-message">{error}</p>}
          {success && <p className="success-message">{success}</p>}
        </>
      )}
    </>
  );
}
